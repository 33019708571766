<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
    products: {
      type: Array,
      required: true,
    },
    marginTop: {
      type: String,
      default: '0px',
    },
    marginBottom: {
      type: String,
      default: '0px',
    },
  },
  data: () => ({
    forceComputeScroll: 0,
    scrollRefreshCounter: 0,
  }),
  computed: {
    canScrollRight() {
      if (this.forceComputeScroll < 0 || this.products.length == 0) {
        return false;
      }

      return this.$refs.container && this.$refs.container.scrollLeft + 10 < this.$refs.container.scrollWidth - this.$refs.container.clientWidth;
    },
    canScrollLeft() {
      if (this.forceComputeScroll < 0 || this.products.length == 0) {
        return false;
      }

      return this.$refs.container && this.$refs.container.scrollLeft > 0;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshScrollableState();
    });
  },
  methods: {
    getBackgroundImageStyle(product) {
      return { backgroundImage: `url('${this.$toLitiumMediaUrl(product.imageId, { maxWidth: 544 })}')` };
    },
    refreshScrollableState() {
      this.forceComputeScroll = this.forceComputeScroll == 0 ? 1 : 0;

      if (this.scrollRefreshCounter > 5) {
        return;
      }

      if (this.products.length != 0 && this.$refs.container && this.$refs.container.scrollWidth == this.$refs.container.clientWidth) {
        this.$nextTick(() => {
          this.scrollRefreshCounter++;
          this.refreshScrollableState();
        });
      }
    },
    getScrollIncrement() {
      return (this.$refs.container.scrollWidth / this.products.length)
        + parseFloat(getComputedStyle(document.documentElement).fontSize);
    },
    goPrev() {
      if (!this.canScrollLeft) {
        return;
      }

      this.$refs.container.scrollLeft -= this.getScrollIncrement();
    },
    goNext() {
      if (!this.canScrollRight) {
        return;
      }

      this.$refs.container.scrollLeft += this.getScrollIncrement();
    },
  },
};
</script>

<template>
  <div class="_layout-maxWidth" :style="{ marginTop: marginTop, marginBottom: marginBottom }">
    <div class="dynamic-products">
      <div class="dynamic-products_title-wrapper">
        <div class="dynamic-products_title">{{ title }}</div>
        <div v-html="text" class="dynamic-products_text"></div>
      </div>
      <div class="dynamic-products_list-wrapper">
        <div class="dynamic-products_list" ref="container" @scroll="refreshScrollableState">
          <div class="dynamic-products_product" v-for="product in products" :key="product.url">
            <router-link class="dynamic-products_product-a" :to="product.url">
              <div class="dynamic-products_product-content">
                <div
                  class="dynamic-products_product-image"
                  :style="getBackgroundImageStyle(product)"
                >
                  <div class="dynamic-products_product-image-overlay"></div>
                </div>
                <div class="dynamic-products_product-info">
                  <div class="dynamic-products_product-name">{{ product.name }}</div>
                  <div
                    class="dynamic-products_product-price"
                    :class="{ 'dynamic-products_product-price--discounted': product.campaignPrice }"
                  >
                    {{ $formatPrice(product.campaignPrice || product.unitPrice) }}
                    <span
                      v-if="product.campaignPrice"
                      class="dynamic-products_product-price-original"
                    >{{ $formatPrice(product.unitPrice) }}</span>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>

        <div class="dynamic-products_navigation">
          <a
            href
            @click.prevent="goPrev"
            v-if="canScrollLeft"
            class="dynamic-products_navigation-prev"
          ></a>
          <a
            href
            @click.prevent="goNext"
            v-if="canScrollRight"
            class="dynamic-products_navigation-next"
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.dynamic-products {
  display: flex;
  flex-direction: column;
}

.dynamic-products_title {
  font-family: var(--font-header);
  font-size: 27px;
  margin-bottom: 8px;
}

.dynamic-products_list {
  display: flex;
  flex-direction: row;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 2rem;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 0.5rem -1rem;
  padding: 0 0.5rem;
}

.dynamic-products_product {
  position: relative;
  display: inline-block;
  list-style-type: none;
  width: calc(100% - 4rem);
  padding: var(--half-grid-margin);
  flex-shrink: 0;
  scroll-snap-align: center;
}

.dynamic-products_product-a,
.dynamic-products_product-a:hover,
.dynamic-products_product-a:focus {
  color: var(--color-black);
}

.dynamic-products_product-content {
  width: 100%;
  padding-top: calc(133.333% + 3.2rem);
}

.dynamic-products_product-image {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0.5rem;
  bottom: 3.7rem;
  right: 0.5rem;
  left: 0.5rem;
}

.dynamic-products_product-image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #efefef;
  mix-blend-mode: darken;
}

.dynamic-products_product-info {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  width: calc(100% - 1rem);
  line-height: 1.3em;
}

.dynamic-products_product-name {
  font-family: var(--font-header);
  font-size: 15px;
  line-height: 1.2em;
  position: absolute;
  bottom: 1.2rem;
}

.dynamic-products_product-price,
.dynamic-products_product-price-original {
  font-size: 12px;
  color: #1a1a1a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dynamic-products_product-price-original {
  position: relative;
  text-decoration: line-through;
}

.dynamic-products_product-price.dynamic-products_product-price--discounted {
  color: var(--color-discount);
}

.dynamic-products_list::-webkit-scrollbar {
  display: none;
}

.dynamic-products_navigation {
  display: block;
  position: relative;
  height: 24px;
}

.dynamic-products_navigation-prev {
  background-image: url(../static/icons/left-arrow.svg);
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.dynamic-products_navigation-next {
  background-image: url(../static/icons/right-arrow.svg);
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  float: right;
}

@media (--tabletAndDesktop) {
  .dynamic-products {
    flex-direction: row;
    margin: auto;
  }

  .dynamic-products_title-wrapper {
    display: block;
    width: 33.33%;
    margin-top: 1rem;
    padding-right: 1rem;
  }

  .dynamic-products_list-wrapper {
    width: 66.66%;
  }

  .dynamic-products_list {
    scroll-padding: 0;
    margin: 0.5rem -0.5rem;
    padding: 0;
  }

  .dynamic-products_product {
    width: 33.333%;
  }

  .dynamic-products_product-price {
    color: #1a1a1a;
  }

  .dynamic-products_product-price,
  .dynamic-products_product-price-original {
    font-size: 14px;
  }
}
</style>
