<script>
import DynamicProducts from '@/components/DynamicProducts.vue';

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    forceComputeScroll: 0,
    scrollRefreshCounter: 0,
  }),
  computed: {
    title() {
      return this.block.fields.Title || '';
    },
    text() {
      return this.block.fields.Text || '';
    },
    products() {
      return this.block.fields.Products;
    },
    marginTop() {
      return this.block.fields.MarginTop;
    },
    marginBottom() {
      return this.block.fields.MarginBottom;
    },
  },
  components: { DynamicProducts },
};
</script>

<template>
  <DynamicProducts
    :products="products"
    :title="title"
    :text="text"
    :margin-top="marginTop"
    :margin-bottom="marginBottom"
  />
</template>
